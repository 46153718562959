<template>
  <div class="container" style="align-items:center" @touchmove="handleMove" @click="handleMove">
    <img style="width:1.67rem;height:0.64rem;margin-top:0.71rem;" src="../assets/images/logo.png" alt="" />
    <input type="tel" id="code" maxlength="4" v-model="inputText" @keydown="keydown">
    <div class="status">验证码已发送至{{ account }}</div>
    <div class="resend" style="color: #74747AFF;" v-if="seconds >= 0">{{ seconds }}s</div>
    <a class="resend" @click="resend" v-else>重新发送</a>
    <div class="code_bg" @click.stop="">
      <div class="code" @click="focus">{{ code.length > 0 ? code.substring(0, 1) : '' }}</div>
      <div class="space"></div>
      <div class="code" @click="focus">{{ code.length > 1 ? code.substring(1, 2) : '' }}</div>
      <div class="space"></div>
      <div class="code" @click="focus">{{ code.length > 2 ? code.substring(2, 3) : '' }}</div>
      <div class="space"></div>
      <div class="code" @click="focus">{{ code.length > 3 ? code.substring(3, 4) : '' }}</div>
    </div>
    <a class="link_btn0" style="margin-top:0.2rem;" @click="verify">验证</a>
  </div>
</template>

<script>

export default {
  props: {
    msg: String
  },
  data() {
    return {
      account: this.$route.query.account,
      code: '',
      inputText: '',
      seconds: 60
    }
  },
  watch: {
    inputText(newVar, oldVar) {
      if (newVar.match("^[0-9]+$")) {
        console.log('')
      }
      else {
        this.inputText = oldVar
      }
      if (newVar == '') {
        this.inputText = ''
      }
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
      if (isAndroid) {
        this.code = this.inputText.toLocaleUpperCase()
      }
      // this.code = newVar.replace(/[^\d]/g, "")
    }
  },
  mounted() {
    this.focus()
    this.beginCountdown()
  },
  methods: {
    handleMove(){
      $('#code').blur();
    },
    beginCountdown() {
      var _this = this
      this.seconds = 60
      this.timer = setInterval(() => {
        _this.seconds = _this.seconds - 1
        if (_this.seconds < 0) {
          clearInterval(_this.timer)
        }
      }, 1000)
    },
    keydown(e) {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
      if (isAndroid) {
        return
      }

      if (e.keyCode != 8 && (e.keyCode < 48 && e.keyCode > 57)) {
        return;
      }
      if (e.key == 'Backspace' || e.key == 'Delete') {
        if (this.code.length > 0) {
          this.code = this.code.substring(0, this.code.length - 1)
        }
        return
      }
      if (this.code.length >= 4) {
        return
      }
      var key = e.key.replace(/[^\d]/g, "")
      this.code = this.code + key
    },
    focus() {
      var myElement = document.getElementById('code');
      myElement.focus();
    },

    resend() {
      var _this = this
      this.$toast('', '', true, -1)
      if (this.$route.query.is_phone) {
        this.$post(this.$api.phoneSendCode, {
          mobile: this.$route.query.account
        }).then(data => {
          global.toast.show = false;
          _this.beginCountdown()
        })
      } else {
        this.$post(this.$api.emailSendCode, {
          email: this.$route.query.account
        }).then(data => {
          global.toast.show = false;
          _this.beginCountdown()
        })
      }
    },
    verify() {
      if (this.code.length < 4) {
        this.$toast('请输入验证码', require('../assets/images/toast_tip.png'))
        return;
      }
      var params = {
        verify_code: this.code,
        invite_code: this.$route.query.invite_code
      }
      if (this.$route.query.is_phone) {
        params.mobile = this.$route.query.account
      }
      else {
        params.email = this.$route.query.account
      }

      var _this = this;
      if (this.$route.query.is_bind) {
        this.$post(this.$route.query.is_phone ? this.$api.phoneBind : this.$api.emailBind, params).then(data => {
          this.$toast('绑定成功', require('../assets/images/toast_success.png'))
          setTimeout(() => {
            this.$router.go(-2)
          }, 2000)

        }).catch(e => {
          this.$toast(e.data.error_message, require('../assets/images/toast_tip.png'))
        })
        return
      }

      this.$post(this.$route.query.is_phone ? this.$api.phoneLogin : this.$api.emailLogin, params).then(data => {

        global.token = data.data.token

        // global.hasLogin = true
        global.userInfo = data.data
        window.localStorage.setItem('userInfo', JSON.stringify(data.data))
        // window.localStorage.setItem('hasLogin','1')
        window.localStorage.setItem('token', data.data.token)
        Promise.all([
          this.$post(this.$api.frontData, {
            code: 'guide_more',
            value: '0'
          }),
          this.$post(this.$api.frontData, {
            code: 'guide_drag',
            value: '0'
          }),
          this.$post(this.$api.frontData, {
            code: 'guide_first_link',
            value: '0'
          })
        ]).then(data => {


          _this.getLoginStep()

          if (global.index) {
            global.index.refresh()
          }
        })


      }).catch(e => {
        this.$toast(e.data.error_message, require('../assets/images/toast_tip.png'))
      })
      // global.hasLogin = true;
      // this.$router.go(-3)

    },
    getLoginStep() {
      var _this = this;
      this.$get(this.$api.frontDatas, { codes: 'profile_completed_step' }).then(data => {
        if (data.length == 0 || data[0].value == 1.0) {
          global.loginStep = 1.0
          _this.updateLoginStep()
          _this.$router.push('/signup/profile').catch(err=>{})
        }
        else {
          if (data[0].value == 2.2 || data[0].value == 3) {
            global.hasLogin = true
            window.localStorage.setItem('hasLogin', '1')
            _this.$router.push('/').catch(err=>{})
          }
          else {
            global.loginStep = data[0].value
            _this.$router.push('/login').catch(err=>{})
          }

        }
      })
    },
    updateLoginStep() {
      this.$post(this.$api.frontData, {
        code: 'profile_completed_step',
        value: 1.0
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.status {
  color: #74747AFF;
  font-size: 0.14rem;
  margin-top: 0.36rem;
}


input {
  width: 10;
  height: 2px;
  opacity: 0;
  left: 0;
  top: -100px;
  position: absolute;
}

.resend {
  color: #FF9900FF;
  font-size: 0.14rem;
  margin-top: 0.1rem;
}

.code_bg {
  display: flex;
  flex-direction: row;
  margin-top: 0.12rem;

  .space {
    width: 0.24rem;
  }


}
</style>